.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Roboto", "RobotoDraft", "Helvetica", "Arial", sans-serif;
  line-height: 1.5;
  font-weight: 400;
  background-color: #f6f6f6;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  /* display: flex;
  place-items: center; */
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

#app {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.vanilla:hover {
  filter: drop-shadow(0 0 2em #f7df1eaa);
}

.card-banner {
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  margin-top: 12px;
}

.read-the-docs {
  color: #888;
}

button {
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 0.5em 1.5em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.25s;
  color: rgb(224, 69, 0);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.141), 0 1px 3px 0 rgba(0, 0, 0, 0.122);
}

/* button:hover {
  background-color: #e35522;
  opacity: 0.9;
  color: #fff;
  border: none;
} */

/* button:focus,
button:focus-visible {
  outline: 1px auto -webkit-focus-ring-color;
} */

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }

  a:hover {
    color: #747bff;
  }

  button {
    background-color: #f9f9f9;
  }
}

.banner-img {
  background-image: url("/public/images/banner-img.jpg");
  background-size: cover;
  background-position: center;
  height: 160px;
  width: 640px;
}

.p-style {
  width: 740px !important;
}

.p-full {
  width: 100%;
}

.wrap-content {
  max-width: 90vw;
  width: 640px;
  margin-top: 12px;
  background-color: #fff;
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 24px;
  padding-top: 22px;
  position: relative;
  box-shadow: none;
  word-wrap: break-word;
}

.wrap-body {
  max-width: 90vw;
  width: 640px;
  margin-top: 12px;
  background-color: #fff;
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 24px;
  padding-top: 22px;
  position: relative;
  box-shadow: none;
  word-wrap: break-word;
}

.wrap-card {
  max-width: 90vw;
  width: 640px;
  margin-top: 12px;
  background-color: #fff;
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  padding: 24px;
  position: relative;
  box-shadow: none;
  word-wrap: break-word;
  margin-bottom: 12px;
}

.line {
  background-color: rgb(255, 152, 0);
  color: rgba(0, 0, 0, 1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 10px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: calc(100% + 2px);
}

.text-heading {
  /* font-family: "docs-Roboto"; */
  font-size: 24pt;
  line-height: 1.25;
  letter-spacing: 0;
}

.text-content {
  font-weight: 400;
  font-size: 11pt;
  line-height: 1.5;
  letter-spacing: 0;
  color: rgb(32, 33, 36);
  margin-top: 12px;
}

.text-content p span a {
  color: #15c;
  cursor: pointer;
}

.text-style {
  font-weight: bold !important;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0;
  margin-bottom: 15px;
}

.text-bold {
  font-weight: bold;
}

.text-requied {
  margin-top: 30px;
  color: rgb(217, 48, 37);
  font-family: Roboto, Arial, sans-serif;
  font-size: 11pt;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 20px;
  margin-bottom: -10px;
}

.line-top {
  margin-top: 15px;
  border-top: 1px solid rgb(218, 220, 224);
  left: 0;
  position: absolute;
  width: 100%;
}

.line-bottom {
  margin: 10px 0;
  border-top: 1px solid rgb(118, 118, 118);
  width: 25%;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  /* display: block; */
  font-size: 11pt;
  font-weight: 400;
  /* margin-bottom: 15px; */
  color: rgb(32, 33, 36);
}

.form-input {
  width: 100%;
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 11pt;
  transition: border-color 0.3s ease;
  background-color: #fff;
}

.form-number {
  width: 100%;
  padding: 3px 10px;
  border-bottom: 1px solid #ddd;
  /* border-radius: 8px; */
  font-size: 11pt;
  transition: border-color 0.3s ease;
  background-color: #fff;
}

.form-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.form-group input[type="radio"] {
  margin-right: 8px;
}

.radio-group {
  display: flex;
  align-items: center;
  gap: 20px;
}

.form-input::placeholder {
  color: #aaa;
}

.radio-group label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.form-group .description {
  font-size: 0.875rem;
  color: #666;
  margin-top: 4px;
}

.style-required {
  color: rgb(217, 48, 37);
  padding-left: 0.25em;
}

.textarea {
  height: 180px;
}

.text-box {
  font-family: "Product Sans", Arial, Helvetica, sans-serif;
  font-size: 24px;
  position: relative;
  top: -3px;
  text-rendering: optimizeLegibility;
  cursor: pointer;
  padding-left: 5x;
}

.style-link {
  color: black;
  opacity: 0.55;
  text-decoration: none;
  margin-top: 50px;
  padding-bottom: 25px !important;
}

.hidden {
  display: none;
}

#loadingSpinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}

#errorPopup {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #000 90%, #0000) 0 /
    calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}
@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}

.containerForm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* Điều chỉnh cho các màn hình nhỏ hơn */

.w--60 {
  width: 60% !important;
}

.submit--button {
  width: 740px;
}

.mg {
  margin-bottom: 0;
}
.w--30 {
  width: 30%;
}
.w--70 {
  width: 70%;
}
.w--45 {
  width: 45%;
}
.w--30 {
  width: 30%;
}
.mt--50 {
  margin-top: 50px;
}
.display--block {
  display: block;
}
@media (max-width: 576px) {
  .p-style {
    width: 100% !important;
  }
  .banner-img {
    width: 100%;
  }
  .form-group {
    margin-bottom: 20px;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    width: 100%;
  }
  .card-banner {
    margin-top: 0;
    border: none;
    border-radius: 0;
  }
  .wrap-content {
    max-width: 100%;
  }
  .wrap-body {
    width: 100%;
  }
  .line {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .mt--50 {
    margin-top: 80px;
  }
  .w--30 {
    width: 100%;
  }
  .w--45 {
    width: 100%;
  }
  .w--60 {
    width: 100% !important;
  }
  .w--70 {
    width: 100% !important;
  }
  .submit--button {
    width: 100% !important;
  }
  .mg {
    margin-bottom: 15px;
  }
  .w--30 {
    width: 100%;
  }
  .input-container {
    width: 100%;
  }
}
/* @media (max-width: 768px) {
  .p-style {
    width: 100% !important;
  }
  .form-group {
    margin-bottom: 20px;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start  !important;
  }
 } */
/* 
 @media (min-width: 768px) {
  .p-style {
    width: 740px !important;
  }
 } */

.ant-form-item {
  margin-bottom: 0;
  width: 60%;
}

@media screen and (max-width: 768px) {
  .ant-form-item {
    margin-bottom: 0;
    width: 100%;
  }
}
